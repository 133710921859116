import * as Sentry from '@sentry/react';
import { useEffect, useState } from 'react';

let eventListeners = [];

export const CookieNames = {
  CONSENT: 'wc__cookie-consent',
};

/**
 * Adds an event listener triggered when the `setCookie` function is called.
 * @param {function} eventListener Event listener function to add.
 */
export function addEventListener(eventListener) {
  if (typeof eventListener === 'function') {
    eventListeners = [...eventListeners, eventListener];
  }
}

export function getCookie(name) {
  const cookieRegExp = new RegExp(`${name}=([^;]*)(?=(;|$))`);
  const match = document.cookie.match(cookieRegExp);
  return match !== null ? match[1] : undefined;
}

/**
 * Removes an event listener.
 * @param {function} eventListener  Event listener function to remove.
 */
export function removeEventListener(eventListener) {
  eventListeners = eventListeners.filter(eL => eL !== eventListener);
}

export function setCookie(name, value, expiryDays, sameSite = 'Strict') {
  if (typeof window !== 'undefined') {
    const exdate = new Date();
    exdate.setDate(exdate.getDate() + (expiryDays || 365));

    const cookie = [
      `${name}=${value}`,
      `expires=${exdate.toUTCString()}`,
      // Note: Will break on wooclap.co.uk but shouldn't be too much of an issue
      `domain=${window.location.hostname.replace(
        /^([a-z]+?\.)(?=[a-z]+?\.)/,
        ''
      )}`,
      `path=/`,
    ];

    //#5764: in production, we want to use the `secure` flag for all cookies.
    //This prevents sending/getting/setting cookie when no in HTTPS
    //eslint-disable-next-line no-undef
    if (window.location.protocol === 'https:') {
      cookie.push('Secure');
      cookie.push(`SameSite=${sameSite}`);
    }

    document.cookie = cookie.join(';');

    // Trigger the event listeners
    eventListeners
      .filter(eventListener => typeof eventListener === 'function')
      .forEach(eventListener =>
        eventListener(name, value, expiryDays, sameSite)
      );
  }
}

export function usePerformanceCookieConsent() {
  const [performanceConsent, setPerformanceConsent] = useState(false);

  useEffect(() => {
    const checkCookieConsent = () => {
      const cookieName = 'wc__cookie-consent';
      const allCookies = document.cookie;
      const cookieMatch = allCookies.match(
        new RegExp(`(^| )${cookieName}=([^;]+)`)
      );

      if (cookieMatch) {
        try {
          const consentData = JSON.parse(decodeURIComponent(cookieMatch[2]));
          setPerformanceConsent(consentData.performance === true);
        } catch (error) {
          Sentry.captureException(new Error(`Error parsing cookie`), {
            extra: {
              error,
            },
          });
        }
      }
    };

    checkCookieConsent();
  }, []);

  return performanceConsent;
}
