import * as Sentry from '@sentry/react';

const environment =
  process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || 'development';

export default {
  init() {
    Sentry.init({
      blacklistUrls: [
        // Chrome extensions
        /^chrome-extension:\//i,
        /^chrome:\/\//i,
        // Hotjar
        /https?:\/\/script\.hotjar\.com\//,
        /https?:\/\/static\.hotjar\.com\//,
        // Intercom
        /https?:\/\/js\.intercomcdn\.com\//,
        // Microsoft library issue
        /lib\/1\/hosted\/telemetry\/oteljs_agave/,
      ],
      dsn: 'https://72eaebaad40786e7af27ca991f03ee7d@o4507842656600064.ingest.de.sentry.io/4508244651868241',
      environment: environment,
      whitelistUrls:
        //eslint-disable-next-line no-undef
        environment !== 'development' ? [/wooclap\.com/] : [],
    });
  },
};
