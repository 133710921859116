import mixpanel from 'mixpanel-browser';

import Sentry from './src/shared/utils/sentry';
import {
  checkAndClearOldCookies,
  isTrackingConsentTrue,
  setTrackingCookies,
} from './src/shared/utils/trackingParams';

let mixpanelInitialized = false;

export const isMixpanelInitialized = () => {
  return mixpanelInitialized && typeof mixpanel !== 'undefined';
};

export const onClientEntry = () => {
  Sentry.init();

  const MIXPANEL_TOKENS = {
    development: process.env.GATSBY_MIXPANEL_DEV_TOKEN,
    staging: process.env.GATSBY_MIXPANEL_STAGING_TOKEN,
    production: process.env.GATSBY_MIXPANEL_PROD_TOKEN,
  };

  const environment =
    process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || 'development';

  const mixpanelToken = MIXPANEL_TOKENS[environment];

  if (!mixpanelToken) {
    return;
  }

  if (isMixpanelInitialized()) {
    return;
  }

  if (isTrackingConsentTrue() && typeof window !== 'undefined') {
    mixpanel.init(mixpanelToken, {
      debug: environment === 'development',
    });

    mixpanelInitialized = true;
    console.info('Mixpanel initialized successfully.');
  } else {
    console.info('Tracking consent not granted or window is undefined.');
  }
};

export const onRouteUpdate = ({ location }) => {
  if (isMixpanelInitialized()) {
    mixpanel.track('View page', {
      'Page url': location.pathname,
    });
  }

  if (isTrackingConsentTrue()) {
    checkAndClearOldCookies();
    setTrackingCookies();
  }

  let lastCookie = document.cookie;
  // rename document.cookie to document._cookie, and redefine document.cookie
  const expando = '_cookie';
  let nativeCookieDesc = Object.getOwnPropertyDescriptor(
    Document.prototype,
    'cookie'
  );
  Object.defineProperty(Document.prototype, expando, nativeCookieDesc);
  Object.defineProperty(Document.prototype, 'cookie', {
    enumerable: true,
    configurable: true,
    get() {
      return this[expando];
    },
    set(value) {
      this[expando] = value;
      // check cookie change
      let cookie = this[expando];
      if (cookie !== lastCookie) {
        try {
          // dispatch cookie-change messages to other same-origin tabs/frames
          let detail = { oldValue: lastCookie, newValue: cookie };
          this.dispatchEvent(
            new CustomEvent('cookiechange', {
              detail: detail,
            })
          );
          channel.postMessage(detail);
        } finally {
          lastCookie = cookie;
        }
      }
    },
  });

  // listen cookie-change messages from other same-origin tabs/frames
  let channel;
  if (typeof BroadcastChannel !== 'undefined') {
    // Use BroadcastChannel if supported
    channel = new BroadcastChannel('cookie-channel');
    channel.onmessage = e => {
      lastCookie = e.data.newValue;
      document.dispatchEvent(
        new CustomEvent('cookiechange', {
          detail: e.data,
        })
      );
    };
  } else {
    // Fallback: Use localStorage events for cross-tab communication
    channel = {
      postMessage: message => {
        localStorage.setItem('cookie-channel', JSON.stringify(message));
        window.dispatchEvent(new Event('storage')); // Simulate a storage event
      },
      close: () => {
        // No cleanup needed for the fallback
      },
    };

    // Listen for storage events as a fallback
    window.addEventListener('storage', event => {
      if (event.key === 'cookie-channel' && event.newValue) {
        const data = JSON.parse(event.newValue);
        lastCookie = data.newValue;
        document.dispatchEvent(
          new CustomEvent('cookiechange', {
            detail: data,
          })
        );
      }
    });
  }
};
