exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-404-404-js": () => import("./../../../src/templates/404/404.js" /* webpackChunkName: "component---src-templates-404-404-js" */),
  "component---src-templates-category-category-js": () => import("./../../../src/templates/Category/Category.js" /* webpackChunkName: "component---src-templates-category-category-js" */),
  "component---src-templates-contact-contact-js": () => import("./../../../src/templates/Contact/Contact.js" /* webpackChunkName: "component---src-templates-contact-contact-js" */),
  "component---src-templates-event-event-js": () => import("./../../../src/templates/Event/Event.js" /* webpackChunkName: "component---src-templates-event-event-js" */),
  "component---src-templates-event-gallery-event-gallery-js": () => import("./../../../src/templates/EventGallery/EventGallery.js" /* webpackChunkName: "component---src-templates-event-gallery-event-gallery-js" */),
  "component---src-templates-event-tag-event-tag-js": () => import("./../../../src/templates/EventTag/EventTag.js" /* webpackChunkName: "component---src-templates-event-tag-event-tag-js" */),
  "component---src-templates-homepage-homepage-js": () => import("./../../../src/templates/Homepage/Homepage.js" /* webpackChunkName: "component---src-templates-homepage-homepage-js" */),
  "component---src-templates-homepage-web-homepage-web-js": () => import("./../../../src/templates/HomepageWeb/HomepageWeb.js" /* webpackChunkName: "component---src-templates-homepage-web-homepage-web-js" */),
  "component---src-templates-newsletter-newsletter-js": () => import("./../../../src/templates/Newsletter/Newsletter.js" /* webpackChunkName: "component---src-templates-newsletter-newsletter-js" */),
  "component---src-templates-page-page-js": () => import("./../../../src/templates/Page/Page.js" /* webpackChunkName: "component---src-templates-page-page-js" */),
  "component---src-templates-post-post-js": () => import("./../../../src/templates/Post/Post.js" /* webpackChunkName: "component---src-templates-post-post-js" */),
  "component---src-templates-pricing-pricing-js": () => import("./../../../src/templates/Pricing/Pricing.js" /* webpackChunkName: "component---src-templates-pricing-pricing-js" */),
  "component---src-templates-quote-generator-quote-generator-js": () => import("./../../../src/templates/QuoteGenerator/QuoteGenerator.js" /* webpackChunkName: "component---src-templates-quote-generator-quote-generator-js" */),
  "component---src-templates-resource-resource-js": () => import("./../../../src/templates/Resource/Resource.js" /* webpackChunkName: "component---src-templates-resource-resource-js" */),
  "component---src-templates-tag-tag-js": () => import("./../../../src/templates/Tag/Tag.js" /* webpackChunkName: "component---src-templates-tag-tag-js" */),
  "component---src-templates-testimonial-testimonial-js": () => import("./../../../src/templates/Testimonial/Testimonial.js" /* webpackChunkName: "component---src-templates-testimonial-testimonial-js" */),
  "component---src-templates-woobinar-woobinar-js": () => import("./../../../src/templates/Woobinar/Woobinar.js" /* webpackChunkName: "component---src-templates-woobinar-woobinar-js" */)
}

